import { Injectable } from '@angular/core';
import { ApiHttpService } from '@core/services/services/api-http.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResourceService } from '@core/services/resource/resource.service';
import { QueryStringParameters } from '@core/services/services/query-string-parameters';
import { ITag } from '@models/tag';
import { ListableResponseType } from '@core/types/http/listable-response.type';

@Injectable({
    providedIn: 'root'
})
export class TagService extends ResourceService {
    constructor(protected httpService: ApiHttpService) {
        super();
    }

    loadTags(
        organisationId: number,
        params: { [key: string]: any } = {},
        filters: { param: string; operator: string; value: any }[] = []
    ): Observable<ListableResponseType<ITag>> {
        return this.httpService
            .get(
                this.createUrlWithQueryParameters(
                    `organisation/${organisationId}/tags`,
                    (qs: QueryStringParameters) => {
                        for (const key of Object.keys(params)) {
                            qs.push(key, params[key]);
                        }

                        for (const filter of filters) {
                            qs.push(filter.param, filter.value, filter.operator);
                        }
                    }
                )
            )
            .pipe(map((response: any) => response.data));
    }

    loadTag(organisationId: number, tagId: number, params: { [key: string]: any } = {}): Observable<ITag> {
        return this.httpService
            .get(
                this.createUrlWithQueryParameters(
                    `organisation/${organisationId}/tags/${tagId}`,
                    (qs: QueryStringParameters) => {
                        for (const key of Object.keys(params)) {
                            qs.push(key, params[key]);
                        }
                    }
                )
            )
            .pipe(map((response: any) => response.data));
    }

    createTag(organisationId: number, data: any): Observable<ITag> {
        return this.httpService
            .post<any>(this.createUrl(`organisation/${organisationId}/tags`), data)
            .pipe(map((response: any) => response.data));
    }

    updateTag(organisationId: number, tagId: number, data: any): Observable<ITag> {
        return this.httpService
            .put<any>(this.createUrl(`organisation/${organisationId}/tags/${tagId}`), data)
            .pipe(map((response: any) => response.data));
    }
}
